import React from 'react'
import { Link } from 'gatsby'

export default ({
  showIndex,
  prev,
  next,
}) =>
  <div className="pure-g">
    <div className="pure-u-10-24 text-left">
      { prev ? (
        <Link to={prev.url} rel="prev" title="Previous post">
          ← {prev.title}
        </Link>
      ) : (
        <Link to="/archive/" rel="up" title="Blog Index">
          ↑	Index
        </Link>
      )}
    </div>
    <div className="pure-u-4-24 text-center">
      {showIndex &&
        <Link to="/archive/" rel="index">
          <i className="fa fa-home" />
        </Link>
      }
    </div>
    <div className="pure-u-10-24 text-right">
      { next ? (
        <Link to={next.url} rel="next" title="Next post">
          {next.title} →
        </Link>
      ) : (
        <Link to="/archive/" rel="up" title="Blog Index">
          Index ↑
        </Link>
      )}
    </div>
  </div>
