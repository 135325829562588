import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import Helmet from 'react-helmet'

//import DefinitionPair from './definition-pair'
import PrevNext from './prev-next'
import Layout from './layout'

import { DiscussionEmbed } from "disqus-react"

import "prismjs/themes/prism-okaidia.css"
import "./code.scss"

const nonAlpha = /[^a-z0-9.]/gi

const kebabCase = name =>
  typeof name === "string" &&
  name.toLowerCase().trimRight().replace(nonAlpha, "-")

const not_empty = x => x

const build_head_from = (node, siteMetadata, prev, next) => {
  const { frontmatter, fields, excerpt } = node
  const { baseUri, blogTitle } = siteMetadata

  const head = {
    title: `${frontmatter.title} - ${blogTitle}`,

    link: [
      { rel: "canonical", href: baseUri + fields.slug },
      prev &&
        { rel: "prev", href: baseUri + prev.url },
      next &&
        { rel: "next", href: baseUri + next.url },
    ].filter(not_empty),

    meta: [
      excerpt &&
        { property: "description", content: excerpt },

      { property: "og:site_name", content: blogTitle },
      { property: "og:type", content: "article" },
      { property: "og:title", content: frontmatter.title },
      { property: "og:url", content: baseUri + fields.slug },
      { property: "og:locale", content: "en_NZ" },
      excerpt &&
        { property: "og:description", content: excerpt },

      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "@davetransom" },
    ].filter(not_empty)
  }

  if (frontmatter.categories) {
    frontmatter.categories.forEach(
      categoryName =>
        head.meta.push({ name: "article:section", content: categoryName})
    )
  }

  if (frontmatter.tags) {
    frontmatter.tags.forEach(
      tagName =>
        head.meta.push({ name: "article:tag", content: tagName})
    )
  }

  return head
}

export default ({
  data,
  pageContext,
}) => {
  const { site, markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const { prev, next } = pageContext

  return <Layout>
    <Helmet {...build_head_from(markdownRemark, site.siteMetadata, prev, next)} />

    <div className="posts">
      <article className="post">
        <header className="post-header">
          <h1 className="post-title">{frontmatter.title}</h1>

          <div className="pure-g">
            <div className="pure-u-1-3 text-left">
              <i className="fa fa-fw fa-calendar" /> posted on <span className="text-nowrap">{frontmatter.date}</span>
            </div>
            <div className="pure-u-2-3 text-right tags">
              <i className="fa fa-fw fa-tags mr-5" />
              {frontmatter.tags && frontmatter.tags.map((tag, i) =>
                <Fragment key={tag}>
                  {i > 0 && " | "}
                  <Link to={`/tag/${kebabCase(tag)}`} className="text-nowrap">{tag}</Link>
                </Fragment>
              )}
            </div>
          </div>
        </header>

        <main
          className="post-description"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </div>

    <nav className="my-4">
      <PrevNext prev={prev} next={next} showIndex={true} />
    </nav>

    { frontmatter.comments === true &&
      <aside>
        <DiscussionEmbed shortname="csharpvitamins" config={{
          url: ("https://www.singular.co.nz" || site.siteMetadata.baseUri) + markdownRemark.fields.slug,
          //identifier: frontmatter.disqus_identifier,  disqus_identifier? doesn't appear to be used
          //title: frontmatter.title,
        }} />
      </aside>
    }
  </Layout>
}

export const pageQuery = graphql`
  query BlogPostBySlug($path: String!) {
    site {
      siteMetadata {
        blogTitle
        baseUri
      }
    }
    markdownRemark(fields: { slug: { eq: $path } }) {
      fields {
        slug
      }
      frontmatter {
        date(formatString: "Do MMM YYYY")
        title
        comments
        disqus_identifier
        tags
      }
      excerpt
      html
    }
  }
`

